<template>
  <div>
    <div>
     
      <b-button-group v-if="activitesDetails.linkId" class="mb-1">
        <b-button
          :variant="!+lang ? 'primary' : 'outline-primary'"
          :to="'/main/activites/details/' + (activitesDetails.language == 1 ? id : activitesDetails.linkId) + '/0'"
          >عربي</b-button
        >
        <b-button
          :to="'/main/activites/details/' + (activitesDetails.language == 2 ? id : activitesDetails.linkId) + '/1'"
          :variant="!+lang ? 'outline-primary' : 'primary'"
          >English</b-button
        >
      </b-button-group>
    </div>
  <ValidationObserver ref="observer">
    <b-form
      
      @submit.prevent="onSubmit"
     
    >
    <b-card no-body class="mb-2">
      <b-card-header class="align-items-center">
        <h4 class="mr-auto mb-0">تفاصيل النشاط</h4>
      </b-card-header>
      <b-card-body>
        <b-card-text>
          <b-row>
            <b-col cols="12" md="6">
              <BTextInputWithValidation
                rules="required"
                name="اسم النشاط"
                label="اسم النشاط"
                placeholder="ادخل اسم النشاط"
                v-model="activitesDetails.title"
              />
            </b-col>
            <b-col cols="12" md="6">
               <BDatepickerWithValidation 
                  rules="required"
                  label="تاريخ النشاط "
                  name="تاريخ النشاط"
                  placeholder="أدخل تاريخ النشاط"
                  v-model="activitesDetails.date"
                />
              </b-col>
            <b-col cols="12" md="6">
                <div class="editor-container mt-2">
                  <quill-editor
                    class="editor"
                    ref="myTextEditor"
                    :value="activitesDetails.description"
                    :options="editorOption"
                    @change="onEditorChange"
                  />
                </div>
                <small class="text-danger mt-5" v-show="!activitesDetails.description">شرح النشاط مطلوب</small>
            </b-col>
            <b-col cols="12" md="6">
              <label class="mt-1">صورة</label>
              <fileDragDrop
                @inValidFile="unValidThumbImage"
                @uploaded="uploadThumbImage"
                id="employee-photo"
                height="100px"
                type="image"
                title="صورة جديدة"
              />
              <img
                :src="!activitesDetails.imageDto.base64
                  ? activitesDetails.imageDto.path.replace('~', $store.getters['app/domainHost'])
                  : activitesDetails.imageDto.base64"
                style="max-height: 320px; object-fit: fill;"
                class="w-100"
              />
            </b-col>
          </b-row>
        </b-card-text>
      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col>
            <div class="d-flex">
              <b-button
                class="mr-1"
                variant="primary"
                style="max-width:100px"
                type="submit"
                @click="updateActivities(activitesDetails)"
                >تعديل</b-button
              >
              <b-button
                type="button"
                to="../"
                variant="outline-primary"
                style="max-width:100px"
                >تراجع</b-button
              >
            </div>
          </b-col>
          <b-col
            style="display: flex;
            justify-content: flex-end;"
          >
            <b-button type="button" style="max-width:100px" variant="outline-primary"
            @click="deleteItemActivities(id)"
              >حذف</b-button
            >
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>
     </b-form>
  </ValidationObserver>
  </div>
</template>
<script>
import BDatepickerWithValidation from "@core/components/inputs/BDatepickerWithValidation";
import BTextInputWithValidation from "@core/components/inputs/BTextInputWithValidation";
import { ValidationObserver } from "vee-validate";
import {mapActions, mapGetters} from "vuex";
import fileDragDrop from "@core/components/file-drag-drop/file-drag-drop.vue";
import { quillEditor } from "vue-quill-editor";
import hljs from 'highlight.js'
export default {
  props: {
    lang: String,
    id: String,
  },
   data: () => ({
       editorOption: {
         modules: {
              toolbar: [
                  ['bold', 'italic', 'underline', 'strike'],
                  ['blockquote', 'code-block'],
                  [{ 'header': 1 }, { 'header': 2 }],
                  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                  [{ 'script': 'sub' }, { 'script': 'super' }],
                  [{ 'indent': '-1' }, { 'indent': '+1' }],
                  [{ 'direction': 'rtl' }],
                  [{ 'size': ['small', false, 'large', 'huge'] }],
                  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                  [{ 'font': [] }],
                  [{ 'color': [] }, { 'background': [] }],
                  [{ 'align': [] }],
                  ['clean'],
                  ['link', 'image', 'video']
              ],
          syntax: {
              highlight: text => hljs.highlightAuto(text).value
          }
        },
        placeholder: "ادخل شرح عن المرحلة",
      },
      }),
  components: {
    fileDragDrop,
    BTextInputWithValidation,
    ValidationObserver,
    BDatepickerWithValidation,
    quillEditor
  },
  computed: {
    ...mapGetters(["activitesDetails"])
  },
  created() {
    this.getActivityById({type: 2, id: this.id})
  },
  methods: {
    ...mapActions(["getActivityById","updateActivities","deleteItemActivities"]),
     onSubmit() {
        this.$refs.observer.validate().then((success) => {
          if(success && this.activitesDetails.imageDto.base64 && !!this.activitesDetails.description) {
             this.updateActivities({
              id: 0,
              name:this.activitesDetails.name,
              imageDto: {
              id: 0,
              path: null,
              base64: this.activitesDetails.imageDto.base64
            }
            });
          }
        })
    },
    onEditorChange(value) {
        this.activitesDetails.description = value.html
      },
    uploadThumbImage(file) {
        this.activitesDetails.imageDto.base64 = file[0].base64;
    },
    unValidThumbImage() {
      console.log("un valid Thumb file");
    },
    
  },
};
</script>
